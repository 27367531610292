import React from "react";
import "./ScientificProgramcss.css";
const ScientificProgram = () => {
  return (
    <>
      <div class="container">
        <h1 class="mt-4">DAY 1 – 22 February 2025</h1>
        <p class="venue">Venue: T.P. Ganesan Auditorium, SRMIST</p>

        <table class="table table-bordered table-striped">
          <thead class="thead-light">
            <tr>
              <th scope="col">Timing</th>
              <th scope="col">Speaker</th>
              <th scope="col">Topic</th>
              <th scope="col">Venue</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>09:00 am - 09:30 am</td>
              <td>Dr. Y. K. Virmani</td>
              <td>Class 2 Composite fillings Clinical technique</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>09:35 am - 10:05 am</td>
              <td>Dr. R. Anil Kumar</td>
              <td>
                Innovative Biomaterials in Restorative Dentistry: Bridging the
                Gap Between Research and Clinical Practice
              </td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>10:10 am - 10:40 am</td>
              <td>Dr. H. Tamizheheivan</td>
              <td>AI in Oral Pathology</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>10:45 am - 11:15 am</td>
              <td>Dr. Mahesh Verma</td>
              <td>Current concepts in Orthodontics</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>11:20 am - 11:50 am</td>
              <td>Dr. Thanvir Mohammed Niazi</td>
              <td>Translating Research into Clinical Oral Surgical Practice</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>11:55 am - 12:25 pm</td>
              <td>Dr. V. K Vijay</td>
              <td>LLLT - An eye opener in PERIO</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>12:30 pm - 01:00 pm</td>
              <td>Dr. Arunima P.R</td>
              <td>Platelet Concentrates: The Pinnacle of Biomimicry</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>01:05 pm - 01:35 pm</td>
              <td>Dr. Balaji K</td>
              <td>Complex Orthodontic patient management with Aligners</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>01:40 pm - 02:10 pm</td>
              <td>Dr. Sharad Kapoor</td>
              <td>Your 1st Implant How ?</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>02:15 pm - 02:45 pm</td>
              <td>Dr. April Gupta</td>
              <td>
                Redefining Care for Radiotherapy-Induced Xerostomia in Head and
                Neck Cancer Patients through TENS Therapy
              </td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>02:50 pm - 03:20 pm</td>
              <td>Dr. V. Raqarajian</td>
              <td>Bruxism - current status and Restorative implications</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>09:00 am - 09:30 am</td>
              <td>Dr. N. Velmurugan</td>
              <td>Overcoming Endodontic Challenges</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>09:35 am - 10:05 am</td>
              <td>Dr. Vijay Adhith</td>
              <td>Functional Correctors, Catch them young</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>10:10 am - 10:40 am</td>
              <td>Dr. R Hariharan</td>
              <td>Clinical outcomes of corticobasal dental implants</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>10:45 am - 11:15 am</td>
              <td>Dr. Ashwin George</td>
              <td>Interdisciplinary Orthodontics: Current Concepts</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>11:20 am - 11:50 am</td>
              <td>Dr. U. Arunmozhi</td>
              <td>
                Predictable management of Gingival Recession - An evidence based
                approach.
              </td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>11:55 am - 12:25 pm</td>
              <td>Dr. Sundhar Raj</td>
              <td>Red Flags in Oral Cancer: How We Approach them in the UK</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>12:30 pm - 01:00 pm</td>
              <td>Dr. Anusha D</td>
              <td>
                Rationale of usage of drugs in pain management in dentistry
              </td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>01:05 pm - 01:35 pm</td>
              <td>Dr. Bagavad Gita</td>
              <td>
                From Research Question to Clinical Translation- Bridging the
                Gap!
              </td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>01:40 pm - 02:10 pm</td>
              <td>Dr. Bala Guhan</td>
              <td>Orthognathic Surgery, Diagnostic Dilemma.</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>02:15 pm - 02:45 pm</td>
              <td>Dr. M. Arvind</td>
              <td>
                Medical Health and Dental treatment Success : Do not neglect the
                close link
              </td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>02:50 pm - 03:20 pm</td>
              <td>Dr. Shahul Hameed Faizee</td>
              <td>
                Braces and Breakthroughs: Research Redefining the Future of
                Orthodontics
              </td>
              <td>Mini Hall 2</td>
            </tr>
          </tbody>
        </table>

        <h2 class="mt-4">Moderators</h2>
        <table class="table table-bordered table-striped">
          <thead class="thead-light">
            <tr>
              <th scope="col">Timing</th>
              <th scope="col">Moderators</th>
              <th scope="col">Topic</th>
              <th scope="col">Venue</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>03:30 pm - 04:30 pm</td>
              <td>Dr. Anil Kohli, Dr. Mahesh Verma, Dr. R K Bali</td>
              <td>“DCI to NDC: The Future of Dental Education in India”</td>
              <td>Main Auditorium</td>
            </tr>
          </tbody>
        </table>

        <h1 class="mt-5">DAY 2 – 23 February 2025</h1>
        <p class="venue">Venue: T.P. Ganesan Auditorium, SRMIST</p>

        <table class="table table-bordered table-striped">
          <thead class="thead-light">
            <tr>
              <th scope="col">Timing</th>
              <th scope="col">Speaker</th>
              <th scope="col">Topic</th>
              <th scope="col">Venue</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>09:00 am - 09:30 am</td>
              <td>Dr. Navaneetha</td>
              <td>Schrauben- a case report</td>
              <td>Lecture Hall 1 (SRMKDC)</td>
            </tr>
            <tr>
              <td>09:00 am - 09:30 am</td>
              <td>Dr. Suma K.</td>
              <td>
                Analysis of Polymer Additives in Dentistry - A research report
              </td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>09:30 am - 10:30 am</td>
              <td>Dr. P.S Thaha</td>
              <td>Dr M. L. SONI ICD ORATION</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>10:30 am - 12:00 pm</td>
              <td></td>
              <td>ICD Convocation</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>12:00 pm - 12:30 pm</td>
              <td></td>
              <td>ICD - Special Awards Ceremony</td>
              <td>Main Auditorium</td>
            </tr>
            <tr>
              <td>10:30 am - 11:00 am</td>
              <td>Dr. Malathi Dayalan</td>
              <td>To conceive and believe, to desire and achieve</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>11:00 am - 11:30 am</td>
              <td>Dr. S. Nagalaxmi</td>
              <td>
                Non-Surgical Management of Transverse Discrepancies in
                Orthodontics patients.
              </td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>11:35 am - 12:05 pm</td>
              <td>Dr. Alex Mathew Muruppel</td>
              <td>Peri-implantitis " Let's shed some Light-</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>11:35 pm - 12:05 pm</td>
              <td>Dr. Bharath Kumar Jayam</td>
              <td>
                Advanced surgical techniques and prosthetic rehabilitation for
                immediate loading Corricobasal implantology
              </td>
              <td>Lecture Hall 1 (SRMKDC)</td>
            </tr>
            <tr>
              <td>01:20 pm - 01:50 pm</td>
              <td>Dr. John Nesan</td>
              <td>Role of Virtual Surgery in Oral and Maxillofacial Surgery</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>01:55 pm - 02:25 pm</td>
              <td>Dr. Rajesh Pillai</td>
              <td>A critical view on irrigant delivery systems</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>02:30 pm - 03:00 pm</td>
              <td>Dr. Sudeep Sarathenandran</td>
              <td>Controversies in Prosthodontics</td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>03:05 pm - 03:35 pm</td>
              <td>Dr. Hoang Trong Hung</td>
              <td>
                Embracing Digitalization in Oral Health Surveys: The Future is
                Now
              </td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>03:40 pm - 04:10 pm</td>
              <td>Dr. Ruwan D. Jayssinghe</td>
              <td>
                Challenges in the Management of Oral Potentially Malignant
                Disorders (OPMDs)
              </td>
              <td>Mini Hall 1</td>
            </tr>
            <tr>
              <td>09:00 am - 09:30 am</td>
              <td>Dr. Anand A. Tripathi</td>
              <td>Biomedical Ethics : success mantra to dentists.</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>10:30 am - 11:00 am</td>
              <td>Dr. Afzal A</td>
              <td>Endocrown in Endodontics</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>11:00 am - 11:30 am</td>
              <td>Dr. Kannan S.</td>
              <td>Paradigm Shift In Today's Orthodontics with AI</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>11:35 am - 12:30 pm</td>
              <td>Dr. Sridhar Premkumar</td>
              <td>From where do original research ideas come from?</td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>01:00 pm - 01:30 pm</td>
              <td>Dr. Ramesh Krishnan</td>
              <td>
                Preanesthetic Assessment of Pediatric Patients for Dental
                Rehabilitation Under General Anaesthesia
              </td>
              <td>Mini Hall 2</td>
            </tr>
            <tr>
              <td>01:15 pm - 01:35 pm</td>
              <td>Dr. C. Saravanan</td>
              <td> Research and future</td>
              <td>Lecture Hall 1 (SRMKDC)</td>
            </tr>
            <tr>
              <td>01:35 pm - 01:50 pm</td>
              <td>Dr. Satyabama Saravanan</td>

              <td>Carrier guidance</td>
              <td>Lecture Hall 1 (SRMKDC)</td>
            </tr>
            <tr>
              <td>01:55 pm - 02:25 pm</td>
              <td>Dr. Pip Dhariwal</td>
              <td rowSpan="4" className="text-center h-100 position-relative">
                <p className="position-absolute top-50 start-50 translate-middle">
                  Challenges faced by a General Dentist on Migration to UK
                </p>
              </td>
              <td rowspan="4" className="text-center h-100 position-relative">
                <p className="position-absolute top-50 start-50 translate-middle">
                  Mini Hall 1
                </p>
              </td>
            </tr>
            <tr>
              <td>02:30 pm - 03:00 pm</td>
              <td>Mr. Neil Carmichael</td>
            </tr>
            <tr>
              <td>03:05 pm - 03:35 pm</td>
              <td>Dr. Gauri Pradhan</td>
            </tr>
            <tr>
              <td>03:40 pm - 04:10 pm</td>
              <td>Dr. Shivani Bhandari</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ScientificProgram;
