import React from "react";
import DelegateUpload from "../../component/admin/uploads/DelegateUpload";
import UGupload from "../../component/admin/uploads/UGuploads";
import PGupload from "../../component/admin/uploads/PGupload";
import SendMessage from "../../component/admin/SendMessage";

const AdminPage = () => {
  return (
    <div className="admin-page" style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", padding: "20px" }}>
      <div className="container">
        <div className="row mb-4">
          <div className="col text-center">
            <h1>Admin</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">Delegate Upload</h5>
                <DelegateUpload />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">UG Upload</h5>
                <UGupload />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">PG Upload</h5>
                <PGupload />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <SendMessage/>
          </div>
      </div>
    </div>
  );
};

export default AdminPage;
