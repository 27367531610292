import React, { useState } from "react";

const Series = () => {
  const [Intro, setIntro] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [series, setSeries] = useState("");
  console.log({ Intro, conclusion, series });

  return (
    <>
      <div className="container py-5 mt-3 d-flex flex-column align-items-center justify-content-center">
        <h1 className="text-center">Clinical case / series</h1>
        <form id="forminput" className="mt-2">
          <div className="form-group mt-3">
            <label for="exampleInputEmail1">Introduction</label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleInputEmail1"
              placeholder="Introduction"
              onChange={(e) => setIntro(e.target.value)}
            />
          </div>

          <div className="form-group mt-3">
            <label for="exampleInput4">Clinical case / series</label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleInput4"
              placeholder="Results"
              onChange={(e) => setSeries(e.target.value)}
            />
          </div>
          <div class="form-group mt-3">
            <label for="exampleFormControlTextarea1">Conclusion</label>
            <textarea
              class="form-control mt-2"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) => setConclusion(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" disabled className="btn btn-success mt-4 w-100">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Series;
