import React from "react";
import HomeBanner from "../../component/Home/HomeBanner";
import "./Trade.css";
const Trade = () => {
  const arr = [
    {
      title: "Platinum Sponsor",
      price: "20 Lakhs",
      points: [
        "Acknowledgement across all printing and advertisement material",
        "Acknowledgement on website Full page advertisement in Souvenir (artwork to be provided by partner)",
        "Option to opt-in 2 Complimentary stall of 9/9 Size",
      ],
    },
    {
      title: "Diamond Sponsor",
      price: "10 Lakhs",
      points: [
        "Acknowledgement across all permissible media",
        "Acknowledgement on website",
        "Full page advertisement in Souvenir (artwork to be provided by partner)",
        "Option to opt-in 1 Complimentary stall of size 9/15",
      ],
    },
    {
      title: "Knowledge Partner",
      price: "10 Lakhs",
      points: [
        "Acknowledgement across all printing and advertisement material",
        "Acknowledgement on website Full page advertisement in Souvenir (artwork to be provided by partner)",
        "Option to opt-in 1 Complimentary stall of 9/9 Size",
      ],
    },
    {
      title: "Gold Sponsor",
      price: "5 Lakhs",
      points: [
        "Acknowledgement across all permissible media",
        "Acknowledgement on website",
        "Full page advertisement in Souvenir (artwork to be provided by partner)",
        "Option to opt-in 1 Complimentary stall of size 9/9",
      ],
    },
    {
      title: "Dinner and Banquet",
      price: "3 Lakhs",
      points: [
        "Acknowledgement across all permissible media",
        "Acknowledgement on website",
        "Full page advertisement in Souvenir (artwork to be provided by partner)",
        "Option to opt-in 1 Complimentary stall of size 9/9",
      ],
    },
    {
      title: "Silver Sponsor",
      price: "2 Lakhs",
      points: [
        "Acknowledgment across all permissible media",
        "Acknowledgment on website",
        "Stall of size 9/9",
      ],
    },
    {
      title: "Preconference",
      price: "1 Lakh",
      points: [
        "Exclusive partnership slab to Sponsor partner of the pre-conference courses on 21.02.2025",
        "Acknowledgment on website",
        "Complimentary Full-page advertisement in souvenir (artwork to be provided by partner)",
      ],
    },
    {
      title: "Stalls",
      price: "30,000/-",
      points: ["Stall of 9/9 Size"],
    },
    {
      title: "Hall Name Front Back, Middle & Back Pages",
      price: "50,000/-",
      points: [],
    },
    {
      title: "Pathway standees -6x3",
      price: "10,000/-",
      points: [],
    },
    {
      title: "Mementoes",
      price: "5,000/- Per mementoes",
      points: [],
    },
  ];

  return (
    <>
      <div className="container-fluid col-lg-11 col-md-11 col-12">
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-lg-12 col-md-10 col-lg-10 ">
            <h1 className="tariffH1 text-lg-start text-md-start text-center">
              TARIFF FOR SPONSORSHIP
            </h1>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 traiffContainer flex-wrap d-flex justify-content-between">
            
            <div className="col-lg-3 col-md-6 col-12">
              <div className="col-11 traiffBox d-flex justify-content-center  flex-column">
                <div className="py-1 text-center">
                  <p className="redHeading">Organizing chairman:</p>
                  <p className="tradePresonName">Dr. N. Vivek </p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Organizing secretary:</p>
                  <p className="tradePresonName">Dr. K.T. Magesh</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Scientific chairman:</p>
                  <p className="tradePresonName">Dr. C. Deepak</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Joint secretary:</p>
                  <p className="tradePresonName">Dr. Deenadayalan.P</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="col-11 traiffBox d-flex justify-content-center  flex-column">
                <div className=" text-center">
                  <p className="redHeading">Treasurer:</p>
                  <p className="tradePresonName">Dr V. Vidyashree Nandini </p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Registration & Reception:</p>
                  <p className="tradePresonName">Dr. K. Vijay Venkatesh</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Hospitality & Banquet:</p>
                  <p className="tradePresonName">Dr.P.L.Ravi Shankar,<br/> Dr. Sivachandran</p>
                </div>
                <div className=" text-center">
                  <p className="redHeading">Spouse committee:</p>
                  <p className="tradePresonName">Dr.Kavitha Ramar</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="col-11 traiffBox d-flex justify-content-center  flex-column">
                <div className="py-1 text-center"> 
                  <p className="redHeading">Trade & sponsorship:</p>
                  <p className="tradePresonName">Dr.S. Shafath Ahmed</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Accommodation:</p>
                  <p className="tradePresonName">Dr. A Victor Samuel</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Transport:</p>
                  <p className="tradePresonName">Dr. Aravindhan.R</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Website & publicity:</p>
                  <p className="tradePresonName">Dr. D. Sihivahanan</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="col-11 traiffBox d-flex justify-content-center  flex-column">
                <div className="py-1 text-center">
                  <p className="redHeading">Gifts:</p>
                  <p className="tradePresonName">Dr. A.V. Saravanan</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Souvenir:</p>
                  <p className="tradePresonName">Dr. Sibyl. S</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Inauguration:</p>
                  <p className="tradePresonName">Dr. Divya. V.C</p>
                </div>
                <div className="py-1 text-center">
                  <p className="redHeading">Audio visual:</p>
                  <p className="tradePresonName">Dr. C. Ganesh</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 my-5">
            <div className="row d-flex ">
              {arr.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-6 col-12 d-flex justify-content-center"
                    key={index}
                  >
                    <div className="col-12 traiffBox d-flex justify-content-between align-items-center flex-column">
                      <div className="py-1">
                        <p className="redHeading textoutline">{item.title}</p>
                      </div>
                      <ul className="py-1">
                        {item.points.map((point, index) => {
                          return <li key={index}>{point}</li>;
                        })}
                      </ul>
                      <div className="py-1">
                        <p className="tradePresonName pb-0 mb-0">
                          {" "}
                          <img
                            src="./img/Trade/MoneyBag.png"
                            className="img-fluid"
                            alt=""
                          />{" "}
                          Rs. <span className="color-green">{item.price}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 my-5">
            <h1 className="tariffH1 text-lg-start text-md-start text-center">
              TARIFF FOR ADVERTISEMENT IN THE SOUVENIR
            </h1>
            <table className="table table-bordered tariffTable">
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Full Page</td>
                  <td>210mm x 280mm</td>
                  <td>Rs. 6,000/-</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>HALF PAGE</td>
                  <td>210mm x 140mm</td>
                  <td>Rs. 4,000/-</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>QUARTER PAGE</td>
                  <td>210mm x 70mm</td>
                  <td>Rs. 2,500/-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trade;
