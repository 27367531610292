import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="container-fluid col-lg-12 pt-5">
        <div className="row">
          <div className="col-lg-4 col-12">
            <div>
              <img
                src="./img/home/foot_logo.webp"
                className="img-fluid foot_logo center-block d-block mx-auto"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-5">
            <h4 className="footer_head">Quicks Links</h4>
            <div className="d-flex justify-content-lg-around justify-content-around">
              <div className="mt-4">
                <p className="quick_parra">
                  <Link to={"/"} className="link_all">
                    Home
                  </Link>
                </p>
                <p className="quick_parra">
                  <Link to={"/"} className="link_all">
                    About
                  </Link>
                </p>
                <p className="quick_parra">
                  <Link to={"/committee"} className="link_all">
                    Committee
                  </Link>
                </p>
                <p className="quick_parra">
                  <Link to={"/"} className="link_all">
                    Registration
                  </Link>
                </p>
                <p className="quick_parra">
                  <Link to={"/abstracts"} className="link_all">
                    Scientific Program
                  </Link>
                </p>
              </div>
              <div className="mt-4">

                <p className="quick_parra">
                  <Link to={"/contact"} className="link_all">
                    Contact
                  </Link>
                </p>
                <p className="quick_parra">
                  <Link to={"/trade"} className="link_all">
                    Trade
                  </Link>
                </p>
                <p className="quick_parra">
                  <Link to={"/accommodation"} className="link_all">
                    Accommodation and <br /> transportation
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.278595025973!2d80.04496557572166!3d12.825265418068302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f70b65001761%3A0x1f5a44f1918d0010!2sSRM%20Kattankulathur%20Dental%20College%20and%20Hospital!5e0!3m2!1sen!2sin!4v1718369467981!5m2!1sen!2sin"
              className="map  center-block d-block mx-auto"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Embed"
            ></iframe>
          </div>
        </div>
        <div className="end_head mt-5 d-flex justify-content-center align-items-center">
          <p className="quick_parra mb-0 mt-4 text_end">
            © 2024 ICD International College of Dentists (REGD) All Rights
            Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
