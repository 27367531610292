import { Link } from "react-router-dom";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

const Hotels = ({ hotel }) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 my-3">
        <div className="card">
          <img src={hotel.img} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{hotel.hotelName}</h5>
            <p className="card-text">{hotel.Location}</p>
            <div className="d-flex align-items-center distance_content">
                <p className="hotel_distance">
                  <LocationOnIcon /> {hotel.distance}
                </p>
              <p className="p-0 m-0 px-3">Distance(Km)</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <Link className="callBtn_link py-2" to={`tel:${hotel.phoneNum}`}>
                <button className="callBtn">
                  <PhoneInTalkIcon /> {hotel.phoneNum}
                </button>
              </Link>
              <Link className="view_link py-2" to={hotel.Link} target="_blank">
                <button className="viewBtn">View More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hotels;
