import { useState } from "react";
import Hotels from "./Hotels";
import "./accommodation.css";
const AccommodationContent = ({ hotel }) => {
  const [hotels] = useState(hotel);
  return (
    <>
      <div className="accommodation-banner">
        <div className="row pt-5">
          <div className="col-12">
            <h2 className="accommodation_head">
              ACCOMMODATION AND <br />
              TRANSPORTATION
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid col-lg-11 col-md-10 col-12  my-5">
        <div className="row d-flex justify-content-center">
            {hotels.map((hotel) => (
              <Hotels key={hotel.id} hotel={hotel} />
            ))}
        </div>
      </div>
    </>
  );
};

export default AccommodationContent;
