import React, { useState } from "react";

const SendMessage = () => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [type, setType] = useState("all");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await fetch(`https://icd-srm.azurewebsites.net/api/notification/${type}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          message,
          subject,
          title,
        }),
      })
        .then((res) => res.json())
        .then((result) => setResponse(result))
        .finally(() => setLoading(false));
    } catch (error) {
        console.log(error)
    }
  };

  return (
    <div className="bg-white p-4 rounded shadow-sm">
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-2">
        <div className="d-flex flex-column gap-3">
          <input
            type="text"
            placeholder="Subject"
            className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setSubject(e.target.value)}
          />
          <input
            type="text"
            placeholder="Title"
            className="p-2  border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            placeholder="Type your message here"
            value={message}
            onChange={handleChange}
            className="w-full px-2 py-2 border rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select
            name=""
            id=""
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="all">All</option>
            <option value="delegates">Delegates</option>
            <option value="pg">PG</option>
            <option value="ug">UG</option>
          </select>
        </div>

        <button
          type="submit"
          className="btn w-25 mx-auto btn-primary text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
};

export default SendMessage;
