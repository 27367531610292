import AccommodationContent from "../../component/Accommodation/AccommodationContent";
import { Hotel } from "../../component/Accommodation/Hotel";
const Accommodation = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <AccommodationContent
        hotel={Hotel}
      />
    </div>
  );
};

export default Accommodation;
